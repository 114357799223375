/**
 * Space for all imported JavaScript Libraries
 *
 * TODO: Find a way to import Font Awesome in a better way
 */

import '@fortawesome/fontawesome-free/js/all.js';

/**
 * Import needed libraries for the website
 */
import { $_ready, Platform, $_, Text } from '@aegis-framework/artemis';
import { Component } from '@aegis-framework/pandora';

import ModIcon from './../assets/img/mod.svg';
import VipIcon from './../assets/img/vip.svg';
import DiscordIcon from './../assets/img/discord.svg';
import TwitchIcon from './../assets/img/twitch.svg';

// Check if the platform supports service workers and register them
if (Platform.serviceWorkers()) {
	navigator.serviceWorker.register(new URL('../service-worker.js', import.meta.url), { type: 'module' });
}


class BotCommand extends Component {
  constructor () {
    super();
  }

  render () {
    const { command, level, description, tags, type, platform } = this.props;

    let image = null;
    let title = '';

    if (level === 'mod') {
      image = ModIcon;
      title = 'Comando para Moderadores';
    } else if (level === 'vip') {
      image = VipIcon;
      title = 'Comando para VIPs';
    } else  {
      image = platform === 'twitch' ? TwitchIcon : DiscordIcon;
      title = 'Comando para Todos';
    }

    let commandType = '/';

    if (type === 'chat') {
      commandType = '!';
    }

    return `
      <div class='animate__backInUp'>
        <div class="card__header">
          <div><b>${commandType}</b></div>
          <div><h3>${command}</h3></div>
          <div><img src="${image}" alt="${level}" title="${title}"></div>
        </div>
        <div class="card__body">
          <p>${description}</p>
        </div>
        <div class="card__footer">${(tags || []).map(t => `<small tag='${t}'>#${t}</small>`).join('')}</div>
      </div>
    `;
  }
}

BotCommand.tag = 'bot-command';

window.customElements.define (BotCommand.tag, BotCommand);

const slashCommands = [
  {
    command: 'abrazo',
    level: 'all',
    description: 'Dale un abrazo a otra persona',
    tags: ['gif'],
  },
  {
    command: 'caracolamagica',
    level: 'all',
    description: 'Hazle una pregunta de sí o no a la caracola mágica',
    tags: ['diversión'],
  },
  {
    command: 'chemsify',
    level: 'all',
    description: 'Convierte un enunciado a idioma Chems',
    tags: ['diversión'],
  },
{
  command: 'clip',
  level: 'mod',
  description: 'Descubre un clip aleatorio del Twitch de Nali',
  tags: ['twitch']
},
  {
    command: 'comando',
    level: 'mod',
    description: 'Agrega un comando para el chat de Twitch',
    tags: ['twitch'],
  },
  {
    command: 'facha',
    level: 'all',
    description: 'Te dice qué tan fachero estás hoy',
    tags: ['diversión'],
  },
  {
    command: 'followage',
    level: 'all',
    description: 'Te dice cuanto tiempo llevas siguiendo a Nali',
    tags: ['twitch']
  },
  {
    command: 'gato',
    level: 'all',
    description: 'Obten una imagen aleatoria de un gato',
    tags: ['diversión'],
  },
  {
    command: 'gemini',
    level: 'all',
    description: 'Interactúa con Gemini, la IA de Google',
    tags: ['ia']
  },
  {
    command: 'golpe',
    level: 'all',
    description: 'Dale un golpe a otra persona',
    tags: ['gif'],
  },
  {
    command: 'gpt',
    level: 'all',
    description: 'Interactúa con Chat GPT',
    tags: ['ia']
  },
  {
    command: 'imagina',
    level: 'all',
    description: 'Genera una imagen con Dall-E',
    tags: ['ia']
  },
  {
    command: 'loop',
    level: 'all',
    description: 'Activa o desactiva la repetición de la canción actual',
    tags: ['música']
  },
  {
    command: 'monito',
    level: 'all',
    description: 'Obten una imagen aleatoria de un monito',
    tags: ['diversión'],
  },
  {
    command: 'pat',
    level: 'all',
    description: 'Dale pat pat a otra persona',
    tags: ['gif'],
  },
  {
    command: 'pause',
    level: 'all',
    description: 'Pausa la reproducción de música',
    tags: ['música']
  },
  {
    command: 'permisos',
    level: 'mod',
    description: 'Modifica los permisos de un usuario',
    tags: ['moderación'],
  },
  {
    command: 'perro',
    level: 'all',
    description: 'Obten una imagen aleatoria de un perro',
    tags: ['diversión'],
  },
  {
    command: 'play',
    level: 'all',
    description: 'Reproduce una canción',
    tags: ['música']
  },
  {
    command: 'shuffle',
    level: 'all',
    description: 'Activa o desactiva el modo aleatorio',
    tags: ['música']
  },
  {
    command: 'skip',
    level: 'all',
    description: 'Reproduce la siguiente canción',
    tags: ['música']
  },
  {
    command: 'stop',
    level: 'all',
    description: 'Detiene la reproducción de música',
    tags: ['música']
  },
  {
    command: 'stream',
    level: 'all',
    description: 'Obtén información sobre el stream actual',
    tags: ['twitch']
  },
  {
    command: 'sueña',
    level: 'all',
    description: 'Genera una imagen con Stability AI',
    tags: ['ia']
  },
  {
    command: 'tarot',
    level: 'all',
    description: 'Obtén una lectura de tarot',
    tags: ['diversión'],
  },
  {
    command: 'timeout',
    level: 'mod',
    description: 'Da un timeout a una persona por un periodo de tiempo',
    tags: ['moderación'],
  },
  {
    command: 'tts',
    level: 'all',
    description: 'Lee tu mensaje con una voz para los demás',
    tags: ['voice'],
  },
  {
    command: 'ver',
    level: 'all',
    description: 'Ve fijamente a otra persona',
    tags: ['gif'],
  },
  {
    command: 'visa',
    level: 'all',
    description: 'Obten tu visa al Reino Cake',
    tags: ['diversión'],
  },
];

const chatCommands = [
  {
    command: 'gemini',
    level: 'all',
    description: 'Interactúa con Gemini, la IA de Google',
    tags: ['ia']
  },
  {
    command: 'gpt',
    level: 'all',
    description: 'Interactúa con Chat GPT',
    tags: ['ia']
  },
  {
    command: 'sparky',
    level: 'all',
    description: 'Sorpresa para Sparky',
    tags: ['diversión'],
  },
];

const twitchCommands = [
  {
    command: 'caracola',
    level: 'all',
    description: 'Hazle una pregunta de sí o no a la caracola mágica',
    tags: [],
  },
  {
    command: 'dado',
    level: 'all',
    description: 'Tira un dado de 6 caras',
    tags: [],
  },
  {
    command: 'facha',
    level: 'all',
    description: 'Te dice qué tan fachero estás hoy',
    tags: [],
  },
  {
    command: 'followage',
    level: 'all',
    description: 'Te dice cuanto tiempo llevas siguiendo a Nali',
    tags: [],
  },
  {
    command: 'gemini',
    level: 'mod',
    description: 'Interactúa con Gemini, la IA de Google',
    tags: [],
  },
  {
    command: 'gpt',
    level: 'mod',
    description: 'Interactúa con Chat GPT',
    tags: [],
  },
  {
    command: 'marvelrivals',
    level: 'all',
    description: 'Obten el ID de Nali en Marvel Rivals',
    tags: [],
  },
  {
    command: 'moneda',
    level: 'all',
    description: 'Tira una moneda al aire',
    tags: [],
  },
  {
    command: 'pc',
    level: 'all',
    description: 'Te dice los specs de la PC de Nali',
    tags: [],
  },
  {
    command: 'random',
    level: 'all',
    description: 'Te da un número aleatorio entre los dos números dados',
    tags: [],
  },
];


const slashTags = [... new Set(slashCommands.flatMap(c => c.tags || []))].map(tag => Text.capitalize(tag)).sort();
const chatTags = [... new Set(chatCommands.flatMap(c => c.tags || []))].map(tag => Text.capitalize(tag)).sort();


// When the page is ready, you can start modifying its DOM!
$_ready(() => {

  $_('.discord-slash-tags').on('click', 'button', function (event) {
    if ($_(this).hasClass('active')) {
      $_(this).removeClass('active');
      $_('.discord-slash-commands bot-command').removeClass('hidden');
    } else {
      $_('.discord-slash-tags button').removeClass('active');
      $_(this).addClass('active');
      const tag = event.target.innerText.replace('#', '').toLowerCase();

      $_('.discord-slash-commands bot-command').each((element) => {
        if ((element.props.tags || []).includes(tag)) {
          element.classList.remove('hidden');
        } else {
          element.classList.add('hidden');
        }
      });
    }
  });

  $_('.discord-chat-tags').on('click', 'button', function (event) {
    if ($_(this).hasClass('active')) {
      $_(this).removeClass('active');
      $_('.discord-chat-commands bot-command').removeClass('hidden');
    } else {
      $_('.discord-chat-tags button').removeClass('active');
      $_(this).addClass('active');
      const tag = event.target.innerText.replace('#', '').toLowerCase();

      $_('.discord-chat-commands bot-command').each((element) => {
        if ((element.props.tags || []).includes(tag)) {
          element.classList.remove('hidden');
        } else {
          element.classList.add('hidden');
        }
      });
    }
  });

  slashTags.forEach((tag, index) => {
    const element = document.createElement('button');
    element.innerText = tag;

    // if (index === 0) {
    //   element.classList.add('active');
    // }

    $_('.discord-slash-tags').append(element);
  });

  slashCommands.forEach(command => {

    const element = document.createElement('bot-command');
    element.setProps({
      command: command.command,
      level: command.level,
      description: command.description,
      tags: command.tags
    });


    $_('.discord-slash-commands').append(element);
  });

  chatTags.forEach((tag, index) => {
    const element = document.createElement('button');
    element.innerText = tag;

    // if (index === 0) {
    //   element.classList.add('active');
    // }

    $_('.discord-chat-tags').append(element);
  });

  chatCommands.forEach(command => {
    const element = document.createElement('bot-command');

    element.setProps({
      command: command.command,
      level: command.level,
      description: command.description,
      tags: command.tags,
      type: 'chat'
    });


    $_('.discord-chat-commands').append(element);
  });

  twitchCommands.forEach(command => {
    const element = document.createElement('bot-command');

    element.setProps({
      command: command.command,
      level: command.level,
      description: command.description,
      tags: command.tags,
      type: 'chat',
      platform: 'twitch'
    });

    $_('.twitch-chat-commands').append(element);
  });
});